import * as React from 'react';
import { navigate } from 'gatsby';
import { useState, useEffect } from 'react';
import { PublicCosting } from '../../templates/PublicCosting/PublicCosting';
import { Router } from '@reach/router';
import { MSALAuthInstance, AuthConfig, AuthRequest } from '../../services/auth.service';
import { Login } from '../../components/Login/Login';
import { RouteComponentProps, Redirect } from '@reach/router';
import Page from '../../templates/Page';
import {Portal} from '../../templates/Portal';

import * as globalStyles from '../../globals.module.scss';

import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { SiteGlobalConfig } from '../../site.config';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
// import * as styles from './costing.module.scss'


interface ISecureRouteProps extends RouteComponentProps{
    component: any;
    [key: string]: any;
}

export default function SecurePortal(props: ISecureRouteProps){
    const request = {
        scopes: ["User.Read", "Sites.Read.All", "Files.Read"],
        authority: `https://login.microsoftonline.com/${SiteGlobalConfig.MSAL_TENANT_ID}`
    }

    const { PRODUCTION_SITE_HOSTNAME } = SiteGlobalConfig;
    
    const { instance, accounts, inProgress } = useMsal();

    const { component: Component, location, path, ...restOfProps } = props;

    useEffect(() => {
        logout();
    }, []);
    
    const logout = async () => {
        await instance.logoutRedirect({ 
            account: accounts[0],
            postLogoutRedirectUri: `https://${PRODUCTION_SITE_HOSTNAME}/`
        });
        navigate('/');
    }
    
    return (
        <section className={ globalStyles.sectionGrid }>
            <span>Logging you out now...</span>
        </section>
    );
}